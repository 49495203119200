/* =============================================================================
 * Card component
 * ========================================================================== */

/**
 * @see /themes/studiometa/templates/_partials/components/card.tpl
 * @see /themes/studiometa/src/scss/components/card.scss
 */

(function (App, $) {
  /**
   * Double tap to go behavior on card component
   * @param  {Event} event The event's object
   * @return {Boolean}       Whether to block the event or not
   */
  function cardDoubleTapToGo(event) {
    const $this = $(this);
    const $link = $this.find('.card-hover__link');
    const className = 'active';

    if ($link.hasClass(className) === false) {
      event.preventDefault();
      $link.addClass('active');
      return false;
    }

    return true;
  }

  // Bind card double tap to go only on mobile and tablet devices
  if (App.is.touch && App.is.tablet) {
    $('.card-hover').on('touchstart click', cardDoubleTapToGo);
  }
})(App, jQuery);
